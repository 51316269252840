@use '../shared/custom.scss';

.nav-menu li a{
    padding: 0.5125rem 1rem !important;
}

.nav-menu li > ul li a{
    padding: 0.5125rem 2rem 0.5125rem 4rem !important;
}

.nav-menu li > ul li > ul li a{
    padding: 0.5125rem 2rem 0.5125rem 3rem !important
}

.nav-menu li > ul li > ul li > ul li a{
    padding: 0.5125rem 2rem 0.5125rem 4rem !important
}

// .nav-menu li > ul{
//     padding-top: 0px !important;
//     padding-bottom: 0px !important;
// }

// .nav-menu > .nav-title {
//     padding: 0.4rem 2rem !important;
//     margin-top: 0.5rem !important;
// }

@media (min-width: 992px){
    .nav-function-minify:not(.nav-function-top) .page-sidebar .primary-nav .nav-menu > li > a + ul > li > a {
        padding-left: 3.375rem !important;
        padding-top: 0.6rem !important;
        padding-bottom: 0.6rem !important;
    }

    .nav-function-minify:not(.nav-function-top) .nav-menu li > ul li > ul li a {
        padding: 0.8125rem 2rem 0.8125rem 2.25rem !important;
    }

    .nav-function-minify:not(.nav-function-top) .nav-menu li > ul li > ul li > ul li a {
        padding: 0.8125rem 2rem 0.8125rem 3.25rem !important;
    }
}


.maxwidth-170{
    max-width: 170px !important;
}

.panel-hdr{
    min-height: 0px !important;
}

.facebook {
    color: #3b5998;
}

.linkedin {
    color: #0077B5;
}

.twitter {
    color: #38A1F3;
}

.instagram {
    color: #F77737;
}

.form-group{
    margin-bottom: 5px !important;
}


.custom-file-label::after {
    content: 'Procurar' !important;
    height: calc(1.47em + 0.50rem);
    line-height: 1.07;
}

.custom-file-label {
    height: calc(1.5em + 0.45rem + 2px) !important;
    line-height: 1.07 !important;
}

.custom-file {
    height: calc(1.5em + 0.45rem + 2px) !important;
}

.bootstrap-filestyle > .form-control {
    height: calc(1.5em + 0.45rem + 2px) !important;
}

.group-span-filestyle > .btn {
    padding: 0.2rem 0.425rem;
}

table.display td {
    padding: 3px 10px;
    white-space:nowrap;
}

.table{
    width: max-content !important;
    min-width: 100%;
}

.table > tbody > tr > td {
    vertical-align: middle !important;
}

.dataTables_wrapper .dataTables_paginate{
    float: none !important;
    text-align: center !important;

}

.select2-container .select2-selection--single {
    height: calc(1.1em + 1rem + 2px) !important;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: calc(1.1em + 1rem + 2px) !important;
}

.pr-custom-botao{
    padding-right: 0.8rem !important;
}

.font-configuracoes {
    font-size: 1.175rem !important;
}

.hr-cinza {
    border-bottom: 1px dashed rgba(21, 23, 26, 0.15) !important;
}

.form-group-table {
    margin-bottom: 0px !important;
}

.select2-container--default .select2-results__option--highlighted[aria-selected]{
    background-color: rgb(187, 187, 187) !important;
}

th.rotate {
    height: 100px;
    white-space: nowrap;
    position:relative;
    min-width: 30px;
  }

th.rotate > div {
    transform: rotate(270deg);
    position: absolute;
    left: 64px;
    right: 0;
    top: 40px;
    margin: auto;
    bottom: -55px;
}

.checkbox {
	label {
		.toggle {
			margin-left: -20px;
			margin-right: 5px;
		}
	}
}
.checkbox-inline {
	.toggle {
		margin-left: -20px;
		margin-right: 5px;
	}
}
.toggle {
	position: relative;
	overflow: hidden;
	width: 185px;
	height: 37px;
	input[type=checkbox] {
		display: none;
	}
}
.toggle-group {
	position: absolute;
	width: 200%;
	top: 0;
	bottom: 0;
	left: 0;
	transition: left .35s;
	-webkit-transition: left .35s;
	-moz-user-select: none;
	-webkit-user-select: none;
}
.toggle.off {
	.toggle-group {
		left: -100%;
	}
}
.toggle-on {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 50%;
	margin: 0;
	border: 0;
	border-radius: 0;
}
.toggle-off {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 50%;
	right: 0;
	margin: 0;
	border: 0;
	border-radius: 0;
}
.toggle-handle {
	position: relative;
	margin: -3px auto 0;
	padding-top: 0;
	padding-bottom: 0;
	height: 84%;
	width: 0;
	border-width: 0 1px;
}
.toggle.btn {
	min-width: 59px;
	min-height: 19px;
}
.toggle-on.btn {
	padding-right: 35px;
}
.toggle-off.btn {
	padding-left: 32px;
}
.toggle.btn-lg {
	min-width: 79px;
	min-height: 45px;
}
.toggle-on.btn-lg {
	padding-right: 31px;
}
.toggle-off.btn-lg {
	padding-left: 31px;
}
.toggle-handle.btn-lg {
	width: 40px;
}
.toggle.btn-sm {
	min-width: 50px;
	min-height: 30px;
}
.toggle-on.btn-sm {
	padding-right: 20px;
}
.toggle-off.btn-sm {
	padding-left: 20px;
}
.toggle.btn-xs {
	min-width: 35px;
	min-height: 22px;
}
.toggle-on.btn-xs {
	padding-right: 28px;
}
.toggle-off.btn-xs {
	padding-left: 30px;
}
a.disabled {
	pointer-events: none;
	cursor: default;
}
