.invalid-feedback{
    display: block !important;
}

.jquery-waiting-base-container {
    position: absolute;
    left: 0px;
    top:0px;
    margin:0px;
    width: 100%;
    height: 100%;
    display:block;
    z-index: 9998;
    opacity: 0.65;
    -moz-opacity: 0.65;
    filter: alpha(opacity = 65);
    background: black;
    background-repeat: no-repeat;
    background-position:50% 50%;
    text-align: center;
    overflow: hidden;
    font-weight: bold;
    color: white;
    padding-top: 15%;
}

.li-notificacao{
    background-color: #ffffff;
}

.li-notificacao:hover{
    transition: all .4s ease;
    -webkit-transition: all .4s ease;
    background-color: #64646438;
}